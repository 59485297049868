@media (prefers-color-scheme: dark) {
 /* great, we're dark-mode only :) This might fix detection of some browser extensions */
}

@font-face {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/satoshi/Satoshi-Regular.woff2") format("woff2"),
    url("../fonts/satoshi/Satoshi-Regular.woff") format("woff");
}

@font-face {
  font-family: "Satoshi";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/satoshi/Satoshi-Italic.woff2") format("woff2"),
    url("../fonts/satoshi/Satoshi-Italic.woff") format("woff");
}

@font-face {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/satoshi/Satoshi-Medium.woff2") format("woff2"),
    url("../fonts/satoshi/Satoshi-Medium.woff") format("woff");
}

@font-face {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/satoshi/Satoshi-Bold.woff2") format("woff2"),
    url("../fonts/satoshi/Satoshi-Bold.woff") format("woff");
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #5b5b5d #323232;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #323232;
}

*::-webkit-scrollbar-track:hover {
  background-color: #383838;
}

*::-webkit-scrollbar-track:active {
  background-color: #5b5b5d;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #5b5b5d;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #d5d5d9;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #d5d5d9;
}
